<template>
  <div class="card" style="min-height: 275px">
    <header class="card-header">
      <p class="card-header-title">
        <b-icon icon="table" custom-size="default" />
        All analyses
      </p>
        <div style="padding-top:8px; margin-right:10px;">
          <b-button rounded
            size="is-small"
            :disabled="checkedRows.length===0"
            class="is-primary button-shadow"
            icon-left="trash"
            label="Remove"
            @click="checkDelete()"
          />
          <span style='margin-left:8px'></span>
          <b-button rounded
            size="is-small"
            :disabled="checkedRows.length===0"
            class="is-primary button-shadow"
            icon-left="download"
            label="Export report"
            @click="exportRMDfiles()"
          />
          <a hidden ref="export" href=""></a>
          <bt-help class="help-export" wfclass="Export"></bt-help>
        </div>
    </header>
    <div class="card-content">
    <b-table
      :striped="true"
      :hoverable="true"
      :data="analyses4table"
      :checked-rows.sync="checkedRows"
      :checkable="true"
      :loading="isLoading"
      default-sort-direction="asc"
      :sortable="true"
      :narrowed="true"
      :sticky-header="analyses4table.length>10"
      sort-icon="angle-up"
      detailed
      detail-key="id"
    >
      <b-table-column
        v-slot="props"
        label="Name"
        field="user_name"
        sortable
      >
        <b-tooltip v-if="props.row.user_name.length > 15">
          <template v-slot:content>{{ props.row.user_name }}</template>{{ props.row.user_name | subStr }}
        </b-tooltip>
        <span v-else>
          {{ props.row.user_name }}
        </span>
      </b-table-column>
      <b-table-column
        v-slot="props"
        label="Function"
        field="meta.func_name"
        sortable
      >
        {{ props.row.meta.func_name}}
      </b-table-column>
      <b-table-column
        v-slot="props"
      >
        <analysis-export :dataset=props.row />
      </b-table-column>
      <section slot="empty" class="section" style="padding: 0rem">
        <div class="content has-text-grey has-text-centered">
          <p>
            <b-icon icon="frown-o" size="is-large" />
          </p>
          <p>Nothing's here&hellip;</p>
        </div>
      </section>
      <template #detail="props">
        <div v-if="props.row.meta.length !== 0">
          <ul class="prettylist">Parameter list:
            <li v-for="(value, key, index) in props.row.meta.func_args_for_print"
            :key="index"
            >
              <i>{{ key }}</i>: {{value}}  <br>
            </li>
          </ul>
        </div>
        <div v-if="props.row.savedPlots.length !== 0">
          <ul class="prettylist">Saved plots:
            <li v-for="(value, key, index) in props.row.savedPlots"
            :key="index">
              <i>{{ key }}</i>:<br>
              <ul class="prettylist">
                <li >Function name: {{ value.function_name }}</li>
                <li >Parameters:
                  <ul class="prettylist">
                    <li v-for="(value, key, index) in value.func_args_for_print"
                      :key="index">
                      <i>{{ key }}</i>: {{value}}<br>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </template>
    </b-table>
    <b-modal v-model="isModalActive" style="z-index: 300">
        <div class="modal-card" style="width: auto;">
            <header class="modal-card-head">
                <p class="modal-card-title">Confirmation</p>
                <button
                    type="button"
                    class="delete"
                    @click="close()"/>
            </header>
            <section class="modal-card-body">
              <b-notification
                v-if="error"
                type="is-danger"
                aria-close-label="Close notification"
                role="alert">
                Unable to retrieve list of datasets impacted.<br/>
                {{ error }}
              </b-notification>
              <template v-if="toDelete.length!==0">
                <span v-if="toDelete.length===1">Are you sure you want to delete {{toDelete[0]}}?</span>
                <div v-else>
                <ul class="prettylist"> This action will delete the following objects:
                  <li v-for="(group,index) in toDelete" :key="index" class="">
                    {{ group }}
                  </li>
                </ul>
                <p>Are you sure you want to proceed?</p>
                </div>
              </template>
              <b-skeleton v-if="toDelete.length===0" size="is-large" :animated="true"></b-skeleton>
            </section>
            <footer class="modal-card-foot">
              <div class="has-text-centered">
                <b-button
                    class="is-primary button-shadow"
                    rounded
                    :disabled="toDelete.length===0"
                    label="Remove"
                    @click="remove()"/>
              </div>
            </footer>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import apiService from '@/services/apiService'
import url from '@/services/urlEnvironment.js'
import { useWorkspaceStore } from '@/stores/workspacestore'

export default {
  name: 'AnalysesTable',
  components: {
    'bt-help': () => import('@/components/help/secondlevel/BtHelp.vue'),
    'analysis-export': () => import('@/components/AnalysisExport.vue')
  },
  data () {
    return {
      isModalActive: false,
      trashObject: null,
      isLoading: false,
      checkedRows: [],
      toDelete: [],
      error: ''
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    return { workspaceStore }
  },
  computed: {
    analyses4table: function () {
      return this.workspaceStore.analyses4table
    },
    selectAnalysisName: function () {
      return this.checkedRows.map(obj => obj.object_name)
    },
    hasSelectedEditor: function () {
      let has = false
      this.checkedRows.some(obj => {
        if (obj.object_name.startsWith('editor')) {
          has = true
        }
      })
      return has
    }
  },
  methods: {
    checkDelete: function () {
      this.toDelete = []
      if (this.selectAnalysisName.length > 0) {
        this.isModalActive = true
        apiService.runRFunction({
          'func_name': 'r_check_delete',
          'objectName': this.selectAnalysisName
        })
          .then(data => {
            if (typeof data.ToDelete === 'string') {
              this.toDelete = [data.ToDelete]
            } else {
              this.toDelete = data.ToDelete
            }
            if (this.toDelete.length === 0) {
              this.error = 'select dataset first'
            }
          })
          .catch(error => {
            this.error = error.data.error
          })
      }
    },
    exportRMDfiles: function () {
      if (this.selectAnalysisName.length > 0) {
        const loadingComponent = this.$buefy.loading.open({
          container: null
        })
        if (this.hasSelectedEditor) {
          this.$buefy.toast.open({
            message: 'Analyses "editor" can not be included in reports.',
            queue: false
          })
        }
        apiService.runRFunction({
          'func_name': 'r_generate_rmd_files',
          'several_analysis': this.selectAnalysisName
        })
          .then(data => {
            this.$refs.export.setAttribute('href', url.API_URL + '/get_file?file=' + data.zipName)
            this.$refs.export.click()
            loadingComponent.close()
          })
          .catch(error => {
            this.error = error.data.error
            loadingComponent.close()
          })
      }
    },
    close: function () {
      this.toDelete = []
      this.isModalActive = false
    },
    remove: function () {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.workspaceStore.runRFunction(
        { 'func_name': 'r_delete_object',
          'objectList': this.toDelete,
          'out_graph': true })
        .then(data => {
          this.toDelete = []
          this.checkedRows = []
          loadingComponent.close()
        })
        .catch(error => {
          this.toDelete = []
          this.checkedRows = []
          this.error = error.data.error
          loadingComponent.close()
        })
      this.isModalActive = false
    }
  },
  filters: {
    subStr: function (string) {
      return string.substring(0, 15) + '...'
    }
  }
}
</script>
<style >
.img-help {
    margin-top:0px !important;
    margin-right:0px !important;
    margin-left:5px !important;
}
</style>
